import React, { createContext, useState } from "react";

type ThemeType = {
  theme: string;
  setTheme: (arg: string) => void;
}

export const ThemeContext = createContext<ThemeType>({ theme: "dark", setTheme: (_) => {} });

export const ThemeProvider = ({children}) => {
  const [theme, setTheme] = useState<string>("dark");
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <ThemeContext.Provider value={{ theme, setTheme, loading, setLoading }}>
      {children}
    </ThemeContext.Provider>
  )
}