exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-about-dark-jsx": () => import("./../../../src/pages/about/about-dark.jsx" /* webpackChunkName: "component---src-pages-about-about-dark-jsx" */),
  "component---src-pages-about-about-light-jsx": () => import("./../../../src/pages/about/about-light.jsx" /* webpackChunkName: "component---src-pages-about-about-light-jsx" */),
  "component---src-pages-about-index-tsx": () => import("./../../../src/pages/about/index.tsx" /* webpackChunkName: "component---src-pages-about-index-tsx" */),
  "component---src-pages-contact-contact-dark-jsx": () => import("./../../../src/pages/contact/contact-dark.jsx" /* webpackChunkName: "component---src-pages-contact-contact-dark-jsx" */),
  "component---src-pages-contact-contact-light-jsx": () => import("./../../../src/pages/contact/contact-light.jsx" /* webpackChunkName: "component---src-pages-contact-contact-light-jsx" */),
  "component---src-pages-contact-index-tsx": () => import("./../../../src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-homepage-home-6-dark-jsx": () => import("./../../../src/pages/homepage/home6-dark.jsx" /* webpackChunkName: "component---src-pages-homepage-home-6-dark-jsx" */),
  "component---src-pages-homepage-home-6-light-jsx": () => import("./../../../src/pages/homepage/home6-light.jsx" /* webpackChunkName: "component---src-pages-homepage-home-6-light-jsx" */),
  "component---src-pages-homepage-index-jsx": () => import("./../../../src/pages/homepage/index.jsx" /* webpackChunkName: "component---src-pages-homepage-index-jsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-blog-post-index-tsx": () => import("./../../../src/templates/BlogPost/index.tsx" /* webpackChunkName: "component---src-templates-blog-post-index-tsx" */)
}

